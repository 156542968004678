import App from "containers/App";
import { BrowserRouter } from "react-router-dom";
import { hydrate } from "react-dom";
import { loadableReady } from "@loadable/component";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";
import useApollo from "apollo";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const helmetContext = {};
const client = useApollo({});

Sentry.init({
  dsn: "https://b203dd65c18c44248d3744cb014ec3da@o797807.ingest.sentry.io/5843891",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

loadableReady().then(() => {
  hydrate(
    <ApolloProvider client={client}>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </ApolloProvider>,
    document.getElementById("root"),
  );
});

if (module.hot) {
  module.hot.accept();
}
