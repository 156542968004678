/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState, useEffect, useRef } from "react";

import Nav from "assets/nav.svg";
import NavCross from "assets/navCross.svg";
import { NavLink } from "react-router-dom";
import detectEthereumProvider from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { subscribe } from "helpers/subscriptions";
import useEthContext from "hooks/useEthContext";

import { store } from "react-notifications-component";

import {
  Wallet,
  truncate,
  Notification,
  sendNotification,
  Cross,
  MenuLoggedIn,
  Loader,
  tokens,
  networkInfo,
  LogosDropdown,
} from "@flrfinance/ff-react-library";

import useClickedOutside from "@flrfinance/ff-react-library/lib/cjs/hooks/useClickedOutside";
import { TokenInc } from "@flrfinance/ff-react-library/lib/esm/containers/MenuLoggedIn/types";

import "@flrfinance/ff-react-library/lib/styles/header.less";
import "./header.less";
import { transformToBaseUnit } from "@flrfinance/ff-react-library/lib/cjs/helpers/utils";
import BigNumber from "bignumber.js";

type Props = {
  loading?: boolean;
  account: string;
  setMobileMenuIsOn?: (v: boolean) => void;
};

const initProvider = () =>
  new WalletConnectProvider({
    rpc: {
      19: "https://songbird.flr.finance/ext/C/rpc",
    },
  });

let provider: WalletConnectProvider;

const loginOptions = [
  {
    title: "Web3 wallet",
  },
  {
    title: "WalletConnect",
  },
];

export default function Header(props: Props) {
  const { state, dispatch } = useEthContext();
  const [connectLoader, setConnectLoader] = useState<boolean>(
    props.loading || false,
  );
  const [mobileNav, setMobileNav] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showLoggedInMenu, setShowLoggedInMenu] = useState<boolean>(false);
  const [web3, setWeb3] = useState<any>();
  const [userTokens, setUserTokens] = useState<TokenInc[]>([] as TokenInc[]);

  const [showMobileMenuLoggedIn, setShowMobileMenuLoggedIn] =
    useState<boolean>(false);

  useEffect(() => {
    props.setMobileMenuIsOn && props.setMobileMenuIsOn(mobileNav);
    if (!mobileNav) {
      setShowMobileMenuLoggedIn(false);
    }
  }, [props.setMobileMenuIsOn, mobileNav, props]);

  const wrapperRef = useRef(null);
  const clicked = useClickedOutside(wrapperRef);

  useEffect(() => {
    setWeb3(state.web3);
  }, [state]);

  useEffect(() => {
    clicked && setShowLoggedInMenu(false);
  }, [clicked]);

  useEffect(() => {
    const getTokens = async () => {
      const tmp: TokenInc[] = [];
      const promises =
        tokens &&
        tokens.map(async (t: any) => {
          const contract = new web3.eth.Contract(t.abi, t.address);
          const balance = await contract.methods
            .balanceOf(state.address)
            .call();
          balance > 0 &&
            t.erc &&
            tmp.push({
              name: t.name,
              balance: transformToBaseUnit(
                balance,
                t.decimals,
                "number",
                5,
              ) as number,
            });
        });
      await Promise.all(promises);
      setUserTokens(tmp);
    };

    web3 && getTokens();
  }, [state.address, web3]);

  useEffect(() => {
    if (web3 && connectLoader) {
      setConnectLoader(false);
    }
  }, [web3, connectLoader]);

  useEffect(() => {
    setConnectLoader(!!props.loading);
  }, [props.loading]);

  const signInMetamask = async () => {
    try {
      setConnectLoader(true);
      //Detect Provider
      const provider = await detectEthereumProvider();
      if (provider) {
        // If the provider returned by detectEthereumProvider is not the same as
        // window.ethereum, something is overwriting it, perhaps another wallet.
        if (provider !== window.ethereum) {
          if (provider !== window.ethereum) {
            sendNotification(
              store,
              <Notification type="error" title="Wallet Issue" icon={<Cross />}>
                <p>
                  Do you have multiple wallets installed? Make sure you have
                  only one web3 wallet enabled on your browser
                </p>
              </Notification>,
            );
          }
          await subscribe(state, dispatch, provider);
          setShow(false);
        }
      } else {
        // Add error messages
        sendNotification(
          store,
          <Notification
            type="error"
            title="No Wallet Detected"
            icon={<Cross />}
          >
            <p>
              No web3 wallet was found. You need to install one in order to use
              this application.
            </p>
          </Notification>,
        );
        setConnectLoader(false);
      }
    } catch (err) {
      // Add error messages
      console.log("catch err!", err);
      sendNotification(
        store,
        <Notification type="error" title="Error" icon={<Cross />}>
          <p>{err.message}</p>
        </Notification>,
      );
      provider = initProvider();
      setConnectLoader(false);
    }
  };

  const walletConnect = async () => {
    try {
      setConnectLoader(true);
      //pops up the QR
      provider = initProvider();
      await provider.enable();
      await subscribe(state, dispatch, provider);
      setConnectLoader(false);
      setShow(false);
    } catch (err) {
      provider = initProvider();
      setConnectLoader(false);
    }
  };

  const headerTheme = {
    dropdown: {
      header: {
        width: "380px",
        height: "48px",
      },
      item: {
        hoverBackground: "grey",
        width: "380px",
      },
    },
  };
  return (
    <>
      <header className={["flex", "column", "main-header"].join(" ")}>
        <div className={["flex", "row", "container", "mobileHeader"].join(" ")}>
          <div
            className={[
              "flex",
              "row",
              "flexAuto",
              "align-center",
              "justify-start",
              "navWrapper",
              "header-height",
            ].join(" ")}
          >
            <LogosDropdown
              getUrl={(u) => window.location.replace(u)}
              product={"flarex"}
              productsAndLinks={[
                {
                  product: "flareloans",
                  url: "https://xfx.flr.finance",
                },
              ]}
            />
            <nav className={["flex", "row"].join(" ")}>
              <li>
                <NavLink to="/" exact activeClassName="active">
                  Swap
                </NavLink>
              </li>
              <li>
                <NavLink to="/pool" activeClassName="active">
                  Liquidity
                </NavLink>
              </li>
            </nav>
          </div>

          <div
            className={["flex", "row", "align-center", "walletWrapper"].join(
              " ",
            )}
          >
            {connectLoader ? (
              <div className={["flex", "row", "align-center"].join(" ")}>
                <Loader />
              </div>
            ) : state.address && state.address.length > 0 ? (
              <div
                className={[
                  "flex",
                  "column",
                  "addressWalletWrapper",
                  "justify-center",
                ].join(" ")}
                ref={wrapperRef}
              >
                <div
                  className={[
                    "flex",
                    "row",
                    "addressWrapper",
                    "align-center",
                  ].join(" ")}
                  onClick={() => {
                    setShowLoggedInMenu(!showLoggedInMenu);
                  }}
                >
                  <Wallet />
                  <div className={["flex", "column"].join(" ")}>
                    <p>{truncate(state.address || "", 12, "...")}</p>
                    <div className={["flex", "balanceWrapper"].join(" ")}>
                      <p>
                        <b>
                          {Number(state.balance) % 1 !== 0
                            ? new BigNumber(state.balance).toFixed(4)
                            : state.balance}
                        </b>{" "}
                        {networkInfo.symbol}
                      </p>
                    </div>
                  </div>
                </div>
                {showLoggedInMenu && (
                  <div className={["menuLoggedInWrapper"].join(" ")}>
                    <MenuLoggedIn
                      hidePriceAndPl
                      address={state.address || ""}
                      balance={
                        new BigNumber(state.balance).modulo(1).isEqualTo(0)
                          ? state.balance.concat(" " + networkInfo.symbol)
                          : new BigNumber(state.balance)
                              .toFixed(4)
                              .concat(" " + networkInfo.symbol)
                      }
                      tokens={userTokens}
                      tabs={["Tokens"]}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div
                className={[
                  "flex",
                  "column",
                  "addressWalletWrapper",
                  "justify-center",
                ].join(" ")}
              >
                <div
                  className={[
                    "flex",
                    "row",
                    "align-center",
                    "addressWrapper",
                  ].join(" ")}
                  onClick={() => signInMetamask()}
                >
                  <Wallet />
                  <p>Connect your Wallet</p>
                </div>
              </div>
            )}
          </div>
          <div
            className={[
              "flex",
              "column",
              "justify-center",
              "mobileNavToggle",
            ].join(" ")}
            onClick={() => setMobileNav(!mobileNav)}
          >
            {mobileNav ? (
              <img src={NavCross} alt="Close Navigation Icon" />
            ) : (
              <img src={Nav} alt="Navigation Icon" />
            )}
          </div>
        </div>
        <div
          className={[
            "flex",
            "column",
            "mobileNav",
            mobileNav && "active",
          ].join(" ")}
        >
          {!showMobileMenuLoggedIn && (
            <nav
              className={["flex", "column", "flexAuto", "justify-center"].join(
                " ",
              )}
            >
              <li onClick={() => setMobileNav(!mobileNav)}>
                <NavLink to="/" exact activeClassName="active">
                  Swap
                </NavLink>
              </li>
              <li onClick={() => setMobileNav(!mobileNav)}>
                <NavLink to="/pool" activeClassName="active">
                  Liquidity
                </NavLink>
              </li>
            </nav>
          )}
          <div className={["flex", "column"].join(" ")}>
            {connectLoader ? (
              <div className={["flex", "row", "align-center"].join(" ")}>
                <Loader />
              </div>
            ) : state.address.length > 0 ? (
              <>
                <div
                  style={{ display: showMobileMenuLoggedIn ? "block" : "none" }}
                >
                  <MenuLoggedIn
                    hidePriceAndPl
                    address={state.address}
                    balance={
                      Number(state.balance) % 1 !== 0
                        ? new BigNumber(state.balance)
                            .toFixed(4)
                            .concat(" " + networkInfo.symbol)
                        : state.balance.concat(" " + networkInfo.symbol)
                    }
                    tokens={userTokens}
                    tabs={["Tokens"]}
                  />
                </div>
                <div
                  onClick={() =>
                    setShowMobileMenuLoggedIn(!showMobileMenuLoggedIn)
                  }
                  className={["flex", "row", "addressWrapper"].join(" ")}
                >
                  <Wallet />
                  <div className={["flex", "column"].join(" ")}>
                    <p>{truncate(state.address, 12, "...")}</p>
                    <div className={["flex", "balanceWrapper"].join(" ")}>
                      <p>
                        <b>
                          {Number(state.balance) % 1 !== 0
                            ? new BigNumber(state.balance).toFixed(4)
                            : state.balance}
                        </b>{" "}
                        {networkInfo.symbol}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={() => signInMetamask()}
                  className={["flex", "row", "connectAction"].join(" ")}
                >
                  <Wallet />
                  <p>Connect your Wallet</p>
                </div>
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
}
