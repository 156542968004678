import React, { createContext } from "react";
import Web3 from "web3";

export type ProviderActions = ActConnect | ActUpdate | ActDisconnect;

export enum ActionType {
  Connect,
  Update,
  Disconnect,
}

export interface ActConnect {
  type: ActionType.Connect;
  payload: ProviderState;
}

export interface ActDisconnect {
  type: ActionType.Disconnect;
  payload: ProviderState | null;
}

export interface ActUpdate {
  type: ActionType.Update;
  payload: ProviderState;
}

export interface ProviderState {
  provider: unknown;
  providerStatus?: Status;
  address?: string;
  balance?: string;
  network?: string;
  networkId?: number | null;
  web3?: Web3 | undefined;
}

export enum Status {
  Connected = "Connected",
  Disconnected = "Disconnected",
  Connecting = "Connecting",
}

export const initProviderState: ProviderState = {
  provider: null,
  providerStatus: Status.Disconnected,
  address: "",
  balance: "",
  network: "",
  networkId: null,
  web3: undefined,
};

const EthContext = createContext<{
  state: ProviderState;
  dispatch: React.Dispatch<ProviderActions>;
}>({
  state: initProviderState,
  dispatch: () => undefined,
});

export { EthContext };
