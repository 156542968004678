import logo from "assets/logo.svg";

import medium from "assets/medium.svg";
import telegram from "assets/telegram.svg";
import twitter from "assets/twitter.svg";
import discord from "assets/discord.svg";
import github from "assets/github.svg";

import "@flrfinance/ff-react-library/lib/styles/footer.less";

const Footer = () => {
  return (
    <footer className={["flex", "column"].join(" ")}>
      <div className={["flex", "column", "container"].join(" ")}>
        <div
          className={[
            "flex",
            "row",
            "flexAuto",
            "align-center",
            "mobile-column",
          ].join(" ")}
        >
          <div className={["flex", "row", "flexAuto"].join(" ")}>
            <img src={logo} alt="Flare Finance Logo" />
          </div>
          <div className={["flex", "column"].join(" ")}>
            <ul className={["flex", "row", "mainFooterNav"].join(" ")}>
              <li>
                <a
                  href="https://flr.finance/#products"
                  target="_blank"
                  rel="noreferrer"
                >
                  Products
                </a>
              </li>
              <li>
                <a
                  href="https://flr.finance/#gov"
                  target="_blank"
                  rel="noreferrer"
                >
                  Governance
                </a>
              </li>
              <li>
                <a
                  href="https://flr.finance/#tokenomics"
                  target="_blank"
                  rel="noreferrer"
                >
                  Tokenomics
                </a>
              </li>
              <li>
                <a
                  href="https://flr.finance/#mission"
                  target="_blank"
                  rel="noreferrer"
                >
                  Mission
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={[
            "flex",
            "row",
            "flexAuto",
            "align-center",
            "mobile-column",
            "metaRow",
          ].join(" ")}
        >
          <div
            className={[
              "flex",
              "row",
              "flexAuto",
              "align-center",
              "copyrightRow",
            ].join(" ")}
          >
            <p>
              <small>Copyright &copy; 2021-2022</small>
            </p>
          </div>
          <div className={["flex", "row"].join(" ")}>
            <ul className={["flex", "row"].join(" ")}>
              <li>
                <a
                  href="https://flarefinance.medium.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={medium} alt="Medium Logo" />
                </a>
              </li>
              <li>
                <a
                  href="https://t.me/FlrFinance"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={telegram} alt="Telegram Logo" />
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/flarefinance"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="Twitter Logo" />
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/flarefinance"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={discord} alt="Discord Logo" />
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/flrfinance"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={github} alt="Github Logo" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
