import Web3 from "web3";
/* eslint-disable indent */
import {
  ActionType,
  ProviderActions,
  ProviderState,
  Status,
} from "./ethContext";

export const providerReducer = (
  state: ProviderState,
  action: ProviderActions,
): ProviderState => {
  switch (action.type) {
    case ActionType.Connect: {
      return {
        ...action.payload,
      };
    }
    case ActionType.Update: {
      return {
        ...action.payload,
      };
    }
    case ActionType.Disconnect: {
      return {
        provider: null,
        providerStatus: Status.Disconnected,
        address: "",
        balance: "",
        network: "",
      };
    }
    default:
      return state;
  }
};

export const connectProvider = (payload: ProviderState): ProviderActions => ({
  type: ActionType.Connect,
  payload,
});

export const updateProvider = async (
  state: ProviderState,
  acc: string,
): Promise<ProviderActions> => {
  let web3: any;
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
  }
  const bal = await web3.eth.getBalance(acc);
  const netwrk = await web3.eth.net.getNetworkType();
  const networkId = await web3.eth.net.getId();
  const payload: ProviderState = {
    ...state,
    providerStatus: Status.Connected,
    address: acc,
    balance: web3.utils.fromWei(bal).toString(),
    network: netwrk,
    networkId: networkId,
  };
  return {
    type: ActionType.Update,
    payload: payload,
  };
};

export const disconnectProvider = (): ProviderActions => ({
  type: ActionType.Disconnect,
  payload: null,
});
