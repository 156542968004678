import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { RestLink } from "apollo-link-rest";
import fetch, { Headers } from "cross-fetch";

import { PrismicLink } from "apollo-link-prismic";

var isBrowser = new Function(
  "try {return this===window;}catch(e){ return false;}",
);
let apolloClient: ApolloClient<NormalizedCacheObject>;

if (!isBrowser()) {
  global.fetch = fetch;
  if (global.Headers == null) {
    global.Headers = Headers;
  }
}

function create(initialState: NormalizedCacheObject, linkParams: object) {
  // Create First Link
  // const graphqlHttpLink = createHttpLink({
  //     uri: 'https://48p1r2roz4.sse.codesandbox.io',
  //     fetch,
  //     credentials: 'same-origin',
  //     ...linkParams
  // });
  const graphqlHttpLink = PrismicLink({
    uri: "https://flare-website.prismic.io/graphql",
  });

  // Create Second Link
  const restAPILink = new RestLink({
    uri: "https://swapi.dev/api/",
    credentials: "same-origin",
    ...linkParams,
  });

  // Usage example of the second API link:
  // const { loading, error, data } = useQuery(EXCHANGE_RATES, { ssr : true, context: { clientName: "rest" } });

  return new ApolloClient({
    connectToDevTools: isBrowser(),
    ssrMode: !isBrowser(),
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === "rest", // Routes the query to the proper client
      restAPILink,
      graphqlHttpLink,
    ),
    cache: new InMemoryCache().restore(initialState),
  });
}

export default function useApollo(linkParams: object) {
  if (!isBrowser()) {
    return create({}, linkParams);
  }
  // isolate client on serverside, but re-use on the browser
  if (!apolloClient) {
    let apolloState = isBrowser()
      ? typeof window.__APOLLO_STATE__ === "string"
        ? JSON.parse(window.__APOLLO_STATE__)
        : window.__APOLLO_STATE__
      : {};
    apolloClient = create(apolloState, linkParams);
  }
  return apolloClient;
}
