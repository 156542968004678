/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ProviderState, ProviderActions, Status } from "contexts/ethContext";
import {
  connectProvider,
  disconnectProvider,
  updateProvider,
} from "contexts/ethContextReducer";
import Web3 from "web3";

interface ProviderMessage {
  type: string;
  data: unknown;
}

export async function connectData(
  provider: any,
  dispatch: React.Dispatch<ProviderActions>,
) {
  try {
    if (!provider) {
      throw new Error("Provider must be defined");
    }
    const web3 = new Web3(provider);
    let acc: string[];
    if (provider?.isMetaMask) {
      acc = await web3.eth.requestAccounts();
    } else {
      acc = provider?.accounts;
    }
    const bal = await web3.eth.getBalance(acc[0]);
    const netwrk = await web3.eth.net.getNetworkType();
    const networkId = await web3.eth.net.getId();

    const payload: ProviderState = {
      provider: provider,
      providerStatus: Status.Connected,
      address: acc[0],
      balance: web3.utils.fromWei(bal).toString(),
      network: netwrk,
      networkId: networkId,
      web3: web3,
    };
    dispatch(connectProvider(payload));
    // await subscribe(payload, dispatch, provider);
  } catch (err) {
    return err;
  }
}

export async function subscribe(
  state: ProviderState,
  dispatch: React.Dispatch<ProviderActions>,
  provider: any,
): Promise<void> {
  const result = await connectData(provider, dispatch);
  if (result && result.code === 4001) {
    throw new Error(JSON.stringify(result));
  }
  const web3 = new Web3(provider);
  provider.on("accountsChanged", async (acc: string[]) => {
    // Handle the new accounts, or lack thereof.
    // "accounts" will always be an array, but it can be empty
    if (!acc.length) {
      dispatch(disconnectProvider());
      return;
    }
    if (web3) {
      const bal = await web3.eth.getBalance(acc[0]);
      const netwrk = await web3.eth.net.getNetworkType();
      const networkId = await web3.eth.net.getId();
      const payload: ProviderState = {
        ...state,
        providerStatus: Status.Connected,
        address: acc[0],
        balance: web3.utils.fromWei(bal).toString(),
        network: netwrk,
        networkId: networkId,
      };
      dispatch(await updateProvider(payload, acc[0]));
    }
  });

  provider.on("chainChanged", (chainId: string) => {
    // Handle the new chain.
    connectData(provider, dispatch);
    // Correctly handling chain changes can be complicated.
    // We recommend reloading the page unless you have good reason not to.
    window.location.reload();
  });

  provider.on("disconnect", () => {
    dispatch(disconnectProvider());
  });

  provider.on("message", (message: ProviderMessage) =>
    console.log("incoming message is:", message),
  );
}
