/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useReducer, useState, useEffect } from "react";
import loadable from "@loadable/component";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Helmet } from "react-helmet-async";
import "./global.less";
import Header from "components/Header";
import Footer from "components/Footer";
import { EthContext } from "contexts/ethContext";
import { providerReducer } from "contexts/ethContextReducer";
import { initProviderState } from "contexts/ethContext";

import { subscribe } from "helpers/subscriptions";
import { EmptyState, Loader, networkInfo } from "@flrfinance/ff-react-library";
import { Mutual } from "@flrfinance/ff-react-library";

const SupplyLiquidity = loadable(
  () => import("pages/SupplyLiquidity/SupplyLiquidity"),
  { ssr: false },
);
const RemoveLiquidity = loadable(
  () => import("pages/RemoveLiquidity/RemoveLiquidity"),
  { ssr: false },
);
const PoolList = loadable(() => import("pages/PoolList/PoolList"), {
  ssr: false,
});
const Swap = loadable(() => import("pages/Swap"), { ssr: false });

const App = () => {
  const [state, dispatch] = useReducer(providerReducer, initProviderState);
  const [loading, setLoading] = useState<boolean>(true);
  const [mobileMenuIsOn, setMobileMenuIsOn] = useState<boolean>(false);

  useEffect(() => {
    const handleEthereum = async (ethereum: any): Promise<void> => {
      // This means that MetaMask is identified so we
      // Force to connect with it
      if (ethereum && ethereum.isMetaMask) {
        try {
          await subscribe(state, dispatch, ethereum);
        } catch (err) {
          setLoading(false);
        }
      }
      setLoading(false);
    };

    if (window.ethereum) {
      handleEthereum(window.ethereum);
    } else {
      window.addEventListener("ethereum#initialized", handleEthereum, {
        once: true,
      });

      // If the event is not dispatched by the end of the timeout,
      // the user probably doesn't have MetaMask installed.
      setTimeout(handleEthereum, 3000); // 3 seconds
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const account = state.address || "";

  // For deletion
  const connectWallet = () => {
    // ethereum.request({ method: 'eth_requestAccounts' }).then(handleNewAccounts)
  };

  const addCostonNetwork = () => {
    const networkParams = {
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: networkInfo.chainId,
          chainName: networkInfo.chainName,
          rpcUrls: networkInfo.rpcUrls,
          blockExplorerUrls: networkInfo.blockExplorerUrls,
          nativeCurrency: {
            name: networkInfo.symbol,
            symbol: networkInfo.symbol, // 2-6 characters long
            decimals: 18,
          },
        },
      ],
    };
    state.provider && state.provider.request(networkParams);
  };

  const mainContentClass = mobileMenuIsOn
    ? "pageMainContentHide"
    : "pageMainContent";
  return (
    <div
      className={["flex", "column", "flexAuto", "universe-wrapper"].join(" ")}
    >
      <Helmet>
        {/* DEFAULT HELMET VALUES */}
        <html lang="en" />
        <title>FlareX</title>
        <meta name="description" content="Very SSR much Fintech" />
      </Helmet>
      <ReactNotification />
      <EthContext.Provider value={{ state, dispatch }}>
        <div
          className={["flex", "row", "warningStripe", "justify-center"].join(
            " ",
          )}
        >
          <p>
            You are using an experimental product suite on a Songbird Canary
            network. Please be aware of the inherit risks involved.
          </p>
        </div>
        <Header
          loading={loading}
          account={account}
          setMobileMenuIsOn={setMobileMenuIsOn}
        />
        {loading ||
        (account && state.networkId !== parseInt(networkInfo.chainId)) ? (
          <div
            className={["flex", "column", "flexAuto", mainContentClass].join(
              " ",
            )}
          >
            <div className={["flex", "column", "container"].join(" ")}>
              {!loading ? (
                <EmptyState
                  icon={<Mutual width={80} height={80} />}
                  title="Action required: Switch network"
                  text={`To be able to use Flare Finance, you need to be on the ${networkInfo.chainName}`}
                  actionText={`Switch to ${networkInfo.chainName}`}
                  action={addCostonNetwork}
                />
              ) : (
                <Loader productTitle="FlareX" />
              )}
            </div>
          </div>
        ) : (
          <div
            className={["flex", "column", "flexAuto", mainContentClass].join(
              " ",
            )}
          >
            <Switch>
              <Route exact path="/" key="swapPage">
                <Swap account={account} />
              </Route>
              <Route exact path="/pool" key="poolPage">
                <PoolList userAddress={account} />
              </Route>
              <Route exact path="/pool/remove/:id" key="removeLiquidityPage">
                <RemoveLiquidity ethBalance="0" userAddress={account} />
              </Route>
              <Route exact path="/pool/supply/:id" key="supplyLiquidityPage">
                <SupplyLiquidity
                  userAddress={account}
                  connect={() => connectWallet()}
                />
              </Route>
              <Redirect exact from="/pool/remove" to="/pool"></Redirect>
              <Redirect exact from="/pool/supply" to="/pool"></Redirect>
            </Switch>
          </div>
        )}
      </EthContext.Provider>
      {!mobileMenuIsOn && <Footer />}
    </div>
  );
};

export default App;
